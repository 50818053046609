import Starter from '../component/admin/views/starter/starter';

// ui components
// import Alerts from '../component/admin/views/ui-components/alert';
// import Badges from '../component/admin/views/ui-components/badge';
// import Buttons from '../component/admin/views/ui-components/button';
// import Cards from '../component/admin/views/ui-components/cards';
// import LayoutComponent from '../component/admin/views/ui-components/layout';
// import PaginationComponent from '../component/admin/views/ui-components/pagination';
// import PopoverComponent from '../component/admin/views/ui-components/popover';
import UserComponent from '../component/admin/views/page/userList';
import PlanComponent from '../component/admin/views/page/planList';
import ConfigComponent from '../component/admin/views/page/configList';
import ConfirmEmailList from '../component/admin/views/page/confirmEmailList';
import ConfirmEmail922List from '../component/admin/views/page/confirmEmail922List';
import SocksComponent from '../component/admin/views/page/socksList';
import OrdersComponent from '../component/admin/views/page/orderList';
import FilterComponent from '../component/admin/views/page/filterList';
import FilterTotalComponent from '../component/admin/views/page/filterTotal';
import FilterDreamhostComponent from '../component/admin/views/page/filterDreamhost';
import FilterCleanComponent from '../component/admin/views/page/filterClean';
import FilterErrorComponent from '../component/admin/views/page/filterError';
import FilterPremiumComponent from '../component/admin/views/page/filterPremium';
import FilterHostComponent from '../component/admin/views/page/filterHost';
import FilterPortComponent from '../component/admin/views/page/filterPort';
import FilterPortTotalComponent from '../component/admin/views/page/filterPortTotal';
import FilterCountryComponent from '../component/admin/views/page/filterCountry';
import FilterCountryPortComponent from '../component/admin/views/page/filterCountryPort';
import IPDeleteComponent from '../component/admin/views/page/IpDeletel';
import SockDeleteComponent from '../component/admin/views/page/sockDelete';
import TypeIpComponent from '../component/admin/views/page/typeIp';
import TypeHostnameComponent from '../component/admin/views/page/typeHostname';
import CheckSockListComponent from '../component/admin/views/page/checkSockList';
import UserIPListComponent from '../component/admin/views/page/userIPList';
import BannerComponent from '../component/admin/views/page/banner';
import AddSocksComponent from '../component/admin/views/page/addSock';
import ToolSockComponent from '../component/admin/views/page/toolSock';
import Profile from '../component/admin/views/page/profile';
import FaqComponent from '../component/admin/views/page/faq';
import AmazonIPListComponent from '../component/admin/views/page/amazonIP';
import Auth2FAComponent from '../component/admin/views/page/auth2fa';
import Auth2FAVerifyComponent from '../component/admin/views/page/auth2faVerify';
import ChangeCountryComponent from '../component/admin/views/page/changeCountry';
import UserResetComponent from '../component/admin/views/page/userReset';
import TrackingSockComponent from '../component/admin/views/page/trackingSock';
import SettingMenuComponent from '../component/admin/views/page/settingMenu';
import CheckSockAgainComponent from '../component/admin/views/page/checkSockAgain';
import ScanIpComponent from '../component/admin/views/page/scanIp';
import UserConvertComponent from '../component/admin/views/page/userConvert';
import UserSercurityComponent from '../component/admin/views/page/userSercurity';
import TicketListComponent from '../component/admin/views/page/ticketList';
import TicketDetailComponent from '../component/admin/views/page/ticketDetail';
import AlertListComponent from '../component/admin/views/page/alertList';
import UserTrialComponent from '../component/admin/views/page/userTiral';
import SettingComponent from '../component/admin/views/page/setting';
import PackageComponent from '../component/admin/views/page/package';

var ThemeRoutes = [
    { 
        path: '/ntsock/dashboard', 
        name: 'Dashboard', 
        icon: 'mdi mdi-view-dashboard', 
        component: Starter 
    },
    { 
        path: '/ntsock/faqs', 
        name: 'FAQs', 
        icon: 'fa fa-question-circle', 
        component: FaqComponent 
    },
    { 
        path: '/ntsock/alert', 
        name: 'Alert', 
        icon: 'fa fa-bell', 
        component: AlertListComponent 
    },
    {
        path: '/ntsock/user_ip',
        name: 'User IP',
        icon: 'fa fa-user',
        component: UserIPListComponent
    },
    // {
    //     path: '/ntsock/amazon_ip',
    //     name: 'Amazon IP',
    //     icon: 'fa fa-eye',
    //     component: AmazonIPListComponent
    // },
    {
        path: '/ntsock/check_sock',
        name: 'Check Sock',
        icon: 'fa fa-wine-glass',
        component: CheckSockListComponent
    },
    // {
    //     path: '/ntsock/filter_sock',
    //     name: 'Filter Sock',
    //     icon: 'fa fa-filter',
    //     component: FilterComponent
    // },
    // {
    //     path: '/ntsock/filter_total',
    //     name: 'Filter Total',
    //     icon: 'fa fa-filter',
    //     component: FilterTotalComponent
    // },
    // {
    //     path: '/ntsock/filter_error',
    //     name: 'Filter Error',
    //     icon: 'fa fa-filter',
    //     component: FilterErrorComponent
    // },
    // {
    //     path: '/ntsock/filter_premium',
    //     name: 'Filter Premium',
    //     icon: 'fa fa-filter',
    //     component: FilterPremiumComponent
    // },
    // {
    //     path: '/ntsock/filter_dreamhost',
    //     name: 'Filter Dreamhost',
    //     icon: 'fa fa-filter',
    //     component: FilterDreamhostComponent
    // },
    // {
    //     path: '/ntsock/filter_clean',
    //     name: 'Filter Clean',
    //     icon: 'fa fa-filter',
    //     component: FilterCleanComponent
    // },
    // {
    //     path: '/ntsock/filter_host',
    //     name: 'Filter Host',
    //     icon: 'fa fa-filter',
    //     component: FilterHostComponent
    // },
    // {
    //     path: '/ntsock/filter_port',
    //     name: 'Filter Port',
    //     icon: 'fa fa-filter',
    //     component: FilterPortComponent
    // },
    // {
    //     path: '/ntsock/filter_port_total',
    //     name: 'Filter Port Total',
    //     icon: 'fa fa-filter',
    //     component: FilterPortTotalComponent
    // },
    // {
    //     path: '/ntsock/filter_country',
    //     name: 'Filter Country',
    //     icon: 'fa fa-filter',
    //     component: FilterCountryComponent
    // },
    // {
    //     path: '/ntsock/filter_country_port',
    //     name: 'Filter Country Port',
    //     icon: 'fa fa-filter',
    //     component: FilterCountryPortComponent
    // },
    // {
    //     path: '/ntsock/ip_delete',
    //     name: 'IP Delete',
    //     icon: 'fa fa-bullseye',
    //     component: IPDeleteComponent
    // },
    {
        path: '/ntsock/sock_delete',
        name: 'Sock Delete',
        icon: 'fa fa-bullseye',
        component: SockDeleteComponent
    },
    // {
    //     path: '/ntsock/type_ip',
    //     name: 'Type IP',
    //     icon: 'fa fa-assistive-listening-systems',
    //     component: TypeIpComponent
    // },
    // {
    //     path: '/ntsock/type_host',
    //     name: 'Type Host',
    //     icon: 'fa fa-assistive-listening-systems',
    //     component: TypeHostnameComponent
    // },
    // {
    //     path: '/ntsock/change_country',
    //     name: 'Change Country',
    //     icon: 'fa fa-assistive-listening-systems',
    //     component: ChangeCountryComponent
    // },
    {
        path: '/ntsock/scan_ip',
        name: 'Scan IP',
        icon: 'fa fa-assistive-listening-systems',
        component: ScanIpComponent
    },
    {
        path: '/ntsock/orders',
        name: 'Orders',
        icon: 'fa fa-shopping-bag',
        component: OrdersComponent
    },
    {
        path: '/ntsock/socks',
        name: 'Socks',
        icon: 'fa fa-podcast',
        component: SocksComponent
    },
    // {
    //     path: '/ntsock/addsocks',
    //     name: 'Add Socks',
    //     icon: 'fa fa-plus-circle',
    //     component: AddSocksComponent
    // },
    {
        path: '/ntsock/confirm_email',
        name: 'Confirm Email',
        icon: 'mdi mdi-email-open',
        component: ConfirmEmailList
    },
    {
        path: '/ntsock/confirm_email_s5',
        name: 'Confirm Email S5',
        icon: 'mdi mdi-email-open',
        component: ConfirmEmail922List
    },
    {
        path: '/ntsock/config',
        name: 'Config',
        icon: 'mdi mdi-image-filter-vintage',
        component: ConfigComponent
    },
    {
        path: '/ntsock/contact',
        name: 'Contact',
        icon: 'mdi mdi-phone',
        component: TicketListComponent
    },
    {
        path: '/ntsock/contact-detail/:id',
        name: 'Contact Dtail',
        icon: '',
        component: TicketDetailComponent,
        hidden: true
    },
    {
        path: '/ntsock/plan',
        name: 'Plans',
        icon: 'fa fa-paper-plane',
        component: PlanComponent
    },
    {
        path: '/ntsock/package',
        name: 'Package',
        icon: 'fa fa-cubes',
        component: PackageComponent
    },
    {
        path: '/ntsock/user',
        name: 'Users',
        icon: 'mdi mdi-account',
        component: UserComponent
    },
    {
        path: '/ntsock/banner',
        name: 'Banner',
        icon: 'mdi mdi-file-image',
        component: BannerComponent
    },
    {
        path: '/ntsock/user_reset',
        name: 'User Reset',
        icon: 'fas fa-sync',
        component: UserResetComponent
    },
    {
        path: '/ntsock/user_trial',
        name: 'User Trial',
        icon: 'fa fa-podcast',
        component: UserTrialComponent
    },
    {
        path: '/ntsock/tracking_sock',
        name: 'Sock Using',
        icon: 'fa fa-magic',
        component: TrackingSockComponent
    },
    {
        path: '/ntsock/check_sock_again',
        name: 'Check Sock Again',
        icon: 'fa fa-snowflake',
        component: CheckSockAgainComponent
    },
    {
        path: '/ntsock/profile',
        name: 'Profile',
        icon: 'mdi mdi-file-image',
        component: Profile,
        hidden: true
    },
    {
        path: '/ntsock/setting_menu',
        name: 'Setting Menu',
        icon: 'mdi mdi-brightness-7',
        component: SettingMenuComponent
    },
    {
        path: '/ntsock/setting',
        name: 'Setting',
        icon: 'mdi mdi-brightness-6',
        component: SettingComponent
    },
    {
        path: '/ntsock/user_convert',
        name: 'User Convert',
        icon: 'mdi mdi-account-convert',
        component: UserConvertComponent
    },
    {
        path: '/ntsock/user_security',
        name: 'User 2FA',
        icon: 'mdi mdi-security',
        component: UserSercurityComponent
    },
    {
        path: '/ntsock/2fa',
        name: '2FA Authenticator',
        icon: 'mdi mdi-account',
        component: Auth2FAComponent
    },
    { 
        path: '/ntsock/verify2fa', 
        name: 'Verify 2FA',
        component: Auth2FAVerifyComponent,
        hidden: true 
    },
    { 
        path: '/ntsock/', 
        pathTo: '/ntsock/dashboard',
        name: 'Dashboard', 
        redirect: true 
    },
];
export default ThemeRoutes;

import { Request } from '../_until';
import { notification } from 'antd';

export const account = {
    login,
    register,
    forgot,
    reset,
    profile,
    change_password,
    change_password_admin,
    change_profile,
    change_avatar,
    get_list_histories,
    reset_plan,
    convert_plan,
    note,
    notification_home,
    telegram_info,
    telegram_connection,
    telegram_disconnect,
    telegram_reset_status,
    get_list_orders,
    get_list_reset,
    get_list_convert,
    check_trial,
    change_show_reset,
    check_data_trial
};

const urls = {
    login: `account/login`,
    register: `account/signup`,
    forgot: `account/forgot`,
    reset: `account/reset`,
    profile: `profile/index`,
    telegram_info: `profile/telegram_info`,
    change_password: `profile/change_password`,
    change_password_admin: `profile/change_password_admin`,
    change_profile: `profile/change_profile`,
    change_avatar: `profile/change_avatar`,
    get_list_histories: `profile/histories`,
    reset_plan: `profile/reset_plan`,
    note: `profile/note`,
    convert_plan: `profile/convert_plan`,
    notification: `profile/nofitication`,
    telegram_connection: `connect/telegram_connection`,
    telegram_disconnect: `connect/telegram_disconnect`,
    telegram_reset_status: `connect/telegram_reset_status`,
    get_list_orders: `profile/orders`,
    get_list_reset: `profile/reset`,
    get_list_convert: `profile/convert`,
    check_trial: `profile/check_trial`,
    change_show_reset: `profile/change_show_reset`,
    check_data_trial: `check-data`,
};

function telegram_connection() {
    return Request.post_website(urls.telegram_connection, {}).then(res => res.data);
};

function telegram_disconnect() {
    return Request.post_website(urls.telegram_disconnect, {}).then(res => res.data);
};

function telegram_reset_status() {
    return Request.post_website(urls.telegram_reset_status, {}).then(res => res.data);
};

async function login(requestOptions) {
    return await Request.post_website(urls.login, requestOptions).then(res => res.data);
};

async function register(requestOptions) {
    return await Request.post_website(urls.register, requestOptions).then(res => res.data);
};

async function forgot(requestOptions) {
    return await Request.post_website(urls.forgot, requestOptions).then(res => res.data);
};

async function reset(requestOptions) {
    return await Request.post_website(urls.reset, requestOptions).then(res => res.data);
};

function profile() {
    return Request.get_website(urls.profile, { show: 1 }).then(res => res.data);
};

function telegram_info() {
    return Request.get_website(urls.telegram_info, { show: 1 }).then(res => res.data);
};

function change_password(requestOptions) {
    return Request.post_website(urls.change_password, requestOptions).then(res => res.data);
};

function change_password_admin(requestOptions) {
    return Request.post_website(urls.change_password_admin, requestOptions).then(res => res.data);
};

function change_profile(requestOptions) {
    return Request.post_website(urls.change_profile, requestOptions).then(res => res.data);
};

function change_avatar(requestOptions) {
    return Request.upload_website(urls.change_avatar, requestOptions).then(res => res.data);
};

function get_list_histories(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_list_histories + '?' + queryString, {}).then(res => res.data);
};

function reset_plan(requestOptions) {
    return Request.post_website(urls.reset_plan, requestOptions).then(res => res.data);
};

function convert_plan(requestOptions) {
    return Request.post_website(urls.convert_plan, requestOptions).then(res => res.data);
};

function note() {
    return Request.post_website(urls.note, {}).then(res => res.data);
};

function notification_home() {
    return Request.get_website(urls.notification, {}).then(res => res.data);
};

function get_list_orders(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_list_orders + '?' + queryString, {}).then(res => res.data);
};

function get_list_reset(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_list_reset + '?' + queryString, {}).then(res => res.data);
};

function get_list_convert(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_list_convert + '?' + queryString, {}).then(res => res.data);
};

function check_trial(requestOptions) {
    return Request.post_website(urls.check_trial, requestOptions).then(res => res.data);
};

function change_show_reset(requestOptions) {
    return Request.post_website(urls.change_show_reset, requestOptions).then(res => res.data);
};

function check_data_trial(userId) {
    return Request.get_website(urls.check_data_trial +'?user_id=' + userId, {}).then(res => res.data);
};

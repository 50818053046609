import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { ClockCircleOutlined } from '@ant-design/icons';

const SendCDKey = () => {
    useEffect(() => {
        document.title = "Send CDKey - " + window?.title;
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="mt-5 pt-5 pb-5 success-box" style={{maxWidth: "640px", background: "#fff", margin: "0 auto", width: "100%"}}>
                        <ClockCircleOutlined style={{color: "#ff9900", marginBottom: "15px"}} />
                        <p style={{fontSize: "16px", marginBottom: "5px"}}>We will send CDkey to your email within 6-12h</p>
                        <p style={{fontSize: "16px"}}>Thank you very much</p>
                        <p style={{fontSize: "16px"}}>
                            <b>Contact us:</b><br/>
                            <b>Email:</b> {window.confirm?.support_email}<br/>
                            <b>Skype:</b> {window.confirm?.skype}<br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendCDKey;
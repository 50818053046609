import Home from '../component/website/Home';
import MyAccount from '../component/website/MyAccount';
import Sock24h from '../component/website/Sock24h';
import SockApi from '../component/website/SockApi';
import Sock5list from '../component/website/Sock5list';
import SockLists from '../component/website/SockLists';
import SockPremium from '../component/website/SockPremium';
import Buysock from '../component/website/Buysock';
import Register from '../component/website/Register';
import Forgot from '../component/website/Forgot';
import Login from '../component/website/Login';
import Success from '../component/website/Success';
import Pendding from '../component/website/Pendding';
import Cancel from '../component/website/Cancel';
import Checksock from '../component/website/Checksock';
import Tool from '../component/website/Tool';
import Using from '../component/website/Using';
import Histories from '../component/website/History';
import FAQsList from '../component/website/FAQsList';
import FAQDetail from '../component/website/FAQDetail';
import Auth2FAComponentFrontend from '../component/website/Auth2fa';
import Auth2FAVerifyComponentFrontend from '../component/website/Auth2faVerify';
import OrdersList from '../component/website/OrdersList';
import ResetList from '../component/website/ResetList';
import ConvertHistory from '../component/website/ConvertHistory';
import ContactList from '../component/website/ContactList';
import ContactDetail from '../component/website/ContactDetail';
import TrialFail from '../component/website/TrialFail';
import SendCDKey from '../component/website/SendCDKey';

var WebsiteRoutes = [
    { 
        path: '/verify2fa.html', 
        name: 'Auth2FAVerifyComponentFrontend',
        component: Auth2FAVerifyComponentFrontend 
    },
    { 
        path: '/2fa.html', 
        name: 'Auth2FAComponentFrontend',
        component: Auth2FAComponentFrontend 
    },
    { 
        path: '/my-account.html', 
        name: 'MyAccount',
        component: MyAccount 
    },
    { 
        path: '/contacts.html', 
        name: 'Contacts',
        component: ContactList 
    },
    { 
        path: '/contact-detail/:id', 
        name: 'contactDetail',
        component: ContactDetail 
    },
    { 
        path: '/faqs.html', 
        name: 'Faqs',
        component: FAQsList 
    },
    { 
        path: '/faq-detail/:slug', 
        name: 'faqDetail',
        component: FAQDetail 
    },
    { 
        path: '/tools.html',
        name: 'tools',
        component: Tool
    },
    { 
        path: '/histories.html',
        name: 'histories',
        component: Histories
    },
    { 
        path: '/reset.html',
        name: 'reset_list',
        component: ResetList
    },
    { 
        path: '/convert.html',
        name: 'convert_list',
        component: ConvertHistory
    },
    { 
        path: '/orders.html', 
        name: 'orders',
        component: OrdersList 
    },
    { 
        path: '/how-to-use-shopsocks5.html', 
        name: 'using',
        component: Using 
    },
    { 
        path: '/view-sock.html', 
        name: 'SockLists',
        component: SockLists 
    },
    { 
        path: '/socks5-list.html', 
        name: 'SockLists',
        component: Sock5list
    },
    { 
        path: '/socks5-premium.html', 
        name: 'SockPremium',
        component: SockPremium
    },
    { 
        path: '/buy-socks5.html', 
        name: 'Buysock',
        component: Buysock 
    },
    { 
        path: '/socks5-24h.html', 
        name: 'Sock24h',
        component: Sock24h 
    },
    { 
        path: '/socks5-api.html', 
        name: 'SockApi',
        component: SockApi 
    },
    { 
        path: '/register.html', 
        name: 'Register',
        component: Register 
    },
    { 
        path: '/forgot.html', 
        name: 'Forgot',
        component: Forgot 
    },
    { 
        path: '/login.html', 
        name: 'Login',
        component: Login 
    },
    { 
        path: '/success.html', 
        name: 'success',
        component: Success 
    },
    { 
        path: '/pendding.html', 
        name: 'pendding',
        component: Pendding 
    },
    { 
        path: '/send-cdkey.html', 
        name: 'send_cdkey',
        component: SendCDKey 
    },
    { 
        path: '/cancel.html', 
        name: 'cancel',
        component: Cancel 
    },
    { 
        path: '/fail.html', 
        name: 'trial-fail',
        component: TrialFail 
    },
    // { 
    //     path: '/checksocks5', 
    //     name: 'Checksock',
    //     component: Checksock
    // },
    { 
        path: '/home', 
        name: 'index',
        component: Home 
    },
    { 
        path: '/', 
        name: 'Home',
        component: Home 
    }
];

export default WebsiteRoutes;

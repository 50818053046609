import React, { useState, useEffect } from 'react';
import { Tabs, Spin, Modal } from 'antd';
import { Link } from "react-router-dom";
import SidebarLeft from './include/SidebarLeft';
import SidebarRight from './include/SidebarRight';
import { plan, config, banner, account } from '../../endpoint';

import s5ABC from '../../assets/images/abc.png';
import s5922 from '../../assets/images/922.png';
import s5PIA from '../../assets/images/pia.png';

const { TabPane } = Tabs;
const Home = () => {
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [data, setData] = useState([]);
    const [settingWebsite, setSettingWebsite] = useState({});
    const [notification, setNotification] = useState("");
    const [dataBanner, setBanner] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [userInfo, setUserInfo] = useState([]);

    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    useEffect(() => {
        document.title = "Home - " + window?.title;

        plan.getListPlan().then(res => {
            if (res.status == 'success') {
                setData(res.response);
                setUserInfo(res.info);
                setLoading(false);
            }
        });

        banner.get_list_banner({position: 'banner_top'}).then(res => {
            if (res.status == 'success') {
                setBanner(res.response);
            }
        });

        account.notification_home().then(res => {
            if (res.status == 'success' && res.response) {
                setNotification(nl2br(res.response.config_description));
                var lastclear = localStorage.getItem('last_time');
                var timeNow  = (new Date()).getTime();
                if (!lastclear || (timeNow - lastclear) > 1000 * 60 * 60 * 24 * 24) {
                    setIsModalVisible(true);
                    localStorage.setItem('last_time', timeNow);
                }
            }
        });

        config.getSettingWebsite().then(res => {
            if (res.status == 'success') {
                setSettingWebsite(res.response);
            }
        });
    }, []);

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalVisibleAds(false);
    };

    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-2">
                    <SidebarLeft />
                </div>
                <div className="col-md-8">
                    <div className="content home-page">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                        <div className={(loading ? " d-none" : "")}>
                            <div className="servicer">
                                <div className="row">
                                    {
                                        dataBanner && dataBanner.length > 0 && dataBanner.map((item, key) => 
                                            <div className={"col-12 col-sm-" + (item.is_full == 1 ? '12' : '6')} key={item.id}>
                                                <a onClick={()=> handleShowModalAds(item)}>
                                                    <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div title="PLAN SOCKS5 PREMIUM" className="plan panel panel-default">
                                <h3 className="panel-heading mb-2">PLAN SOCKS5 PREMIUM</h3>
                                <div className="panel-body">
                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_premium }}></div>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered remove-margin table-plan list-premium table-list-mobile">
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        <th>Daily Expired</th>
                                                        <th>Price($)</th>
                                                        <th>Credit Expired</th>
                                                        <th>Total Socks5 Receive</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.Premium && data?.Premium.length > 0 && data?.Premium.map((item, key) => 
                                                            <tr key={item.plan_id}>
                                                                <td data-title="Plan">{item.plan_name}</td>
                                                                <td data-title="Daily Expired">{item.plan_expired == 0 ? window.confirm.number_day + ' Days' : item.plan_expired + ' Days'}</td>
                                                                <td data-title="Price($)">{item.plan_price} $</td>
                                                                <td data-title="Credit Expired">{"Until Credit = 0"}</td>
                                                                <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div title="PLAN SOCKS5 DAILY" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">PLAN SOCKS5 DAILY</h3>
                                <div className="panel-body">
                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_daily }}></div>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Tabs defaultActiveKey="30day">
                                            <TabPane tab="15 Days" key="15day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-title="Plan">DAILY 5 SOCKS5 <br/>1 DAY</td>
                                                                <td data-title="Daily Limit">5</td>
                                                                <td data-title="Price($)">0$</td>
                                                                <td data-title="Expired">1 Day</td>
                                                                <td data-title="Total Socks5 Receive">5</td>
                                                            </tr>
                                                            {
                                                                data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                    item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="30 Days" key="30day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-title="Plan">DAILY 5 SOCKS5 <br/>1 DAY</td>
                                                                <td data-title="Daily Limit">5</td>
                                                                <td data-title="Price($)">0$</td>
                                                                <td data-title="Expired">1 Day</td>
                                                                <td data-title="Total Socks5 Receive">5</td>
                                                            </tr>
                                                            {
                                                                data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                    item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="365 Days" key="365day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-title="Plan">DAILY 5 SOCKS5 <br/>1 DAY</td>
                                                                <td data-title="Daily Limit">5</td>
                                                                <td data-title="Price($)">0$</td>
                                                                <td data-title="Expired">1 Day</td>
                                                                <td data-title="Total Socks5 Receive">5</td>
                                                            </tr>
                                                            {
                                                                data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                    item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                            <div title="PLAN SOCKS5 LIST" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">PLAN SOCKS5 LIST</h3>
                                <div className="panel-body">
                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_list }}></div>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Tabs defaultActiveKey="list-30day">
                                            <TabPane tab="15 Days" key="list-15day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-title="Plan">LIST 5 SOCKS5 <br/>1 DAY</td>
                                                                <td data-title="Daily Limit">5</td>
                                                                <td data-title="Price($)">0$</td>
                                                                <td data-title="Expired">1 Day</td>
                                                                <td data-title="Total Socks5 Receive">5</td>
                                                            </tr>
                                                            {
                                                                data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                    item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="30 Days" key="list-30day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-title="Plan">LIST 5 SOCKS5 <br/>1 DAY</td>
                                                                <td data-title="Daily Limit">5</td>
                                                                <td data-title="Price($)">0$</td>
                                                                <td data-title="Expired">1 Day</td>
                                                                <td data-title="Total Socks5 Receive">5</td>
                                                            </tr>
                                                            {
                                                                data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                    item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="365 Days" key="list-365day">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                        <thead>
                                                            <tr>
                                                                <th>Plan</th>
                                                                <th>Daily Limit</th>
                                                                <th>Price($)</th>
                                                                <th>Expired</th>
                                                                <th>Total Socks5 Receive</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-title="Plan">LIST 5 SOCKS5 <br/>1 DAY</td>
                                                                <td data-title="Daily Limit">5</td>
                                                                <td data-title="Price($)">0$</td>
                                                                <td data-title="Expired">1 Day</td>
                                                                <td data-title="Total Socks5 Receive">5</td>
                                                            </tr>
                                                            {
                                                                data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                    item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                        <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                        <td data-title="Price($)">{item.plan_price} $</td>
                                                                        <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                        <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                    </tr>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>        
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                            <div title="922 S5Proxy" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">922 S5Proxy</h3>
                                <div className="panel-body">
                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_922 }}></div>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Plan</th>
                                                        <th>Credit</th>
                                                        <th>Price($)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.s5922 && data?.s5922.length > 0 && data?.s5922.map((item, key) => (
                                                                <tr key={item.id}>
                                                                    <td className="no-padding"><img src={s5922} width="135"/></td>
                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                    <td data-title="Credit">{item.credit}</td>
                                                                    <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div title="PIA S5Proxy" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">PIA S5Proxy</h3>
                                <div className="panel-body">
                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_pia }}></div>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Plan</th>
                                                        <th>Credit</th>
                                                        <th>Price($)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.s5pia && data?.s5pia.length > 0 && data?.s5pia.map((item, key) => (
                                                                <tr key={item.id}>
                                                                    <td className="no-padding"><img src={s5PIA} width="145"/></td>
                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                    <td data-title="Credit">{item.credit}</td>
                                                                    <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div title="ABC S5Proxy" className="plan panel panel-default mt-3">
                                <h3 className="panel-heading mb-2">ABC S5Proxy</h3>
                                <div className="panel-body">
                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_abc }}></div>
                                </div>
                            </div>
                            <div className="plan-table">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered remove-margin table-plan table-list-mobile">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Plan</th>
                                                        <th>Credit</th>
                                                        <th>Price($)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.s5abc && data?.s5abc.length > 0 && data?.s5abc.map((item, key) => (
                                                                <tr key={item.id}>
                                                                    <td className="no-padding"><img src={s5ABC} width="135"/></td>
                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                    <td data-title="Credit">{item.credit}</td>
                                                                    <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <SidebarRight />
                </div>
            </div>
            <div className="page-bottom home-payment-icon">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://perfectmoney.is/login.html" className='payment1 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto  text-center">
                        <a href="https://login.wmtransfer.com/" className='payment2 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://blockchain.info/" className='payment3 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment4 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment5 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment6 payment' target="_blank"></a>
                    </div>
                    <div className="col-12 col-md-auto text-center">
                        <a href="https://www.binance.com/" className='payment7 payment' target="_blank"></a>
                    </div>
                </div>
            </div>
            <Modal title="Notification" visible={isModalVisible} onCancel={handleCancel} footer={null} width={640}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: notification }}></p>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Pagination, Spin, notification, message } from 'antd';
import { account, authenticator } from '../../endpoint';
import { getParamsByUrlPaging } from '../../_until';

const OrdersList = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [dataLists, setDataLists] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [dataForm, setDataForm] = useState({});
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    const onChange = (page, pageSize) =>{
        setCurrent(page);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);

        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        account.get_list_orders(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setFirst(false);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    const getPaymentMethod = (type) => {
        if (type == 'pm') {
            return 'Perfect Money';
        } else if (type == 'wmz') {
            return 'WebMoney';
        } else if (type == 'bitcoin' || type == 'btc') {
            return 'Bitcoin (BTC)';
        } else if (type == 'wex') {
            return 'Wex';
        } else if (type == 'bitcoincash') {
            return 'Bitcoin Cash (BCH)';
        } else if (type == 'erc20') {
            return 'USDT (ERC20)';
        } else if (type == 'trc20') {
            return 'USDT (TRC20)';
        } else if (type == 'bep20') {
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else {
            return "Other";
        }
    }

    useEffect(() => {
        document.title = "Orders History - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadList({all: 1, ...paramQuery});
                    if(paramQuery?.current){
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            <div className="content">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">Orders History</div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Plan</th>
                                                <th>Price</th>
                                                <th>Method</th>
                                                <th>Status</th>
                                                <th>Date Expired</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((current - 1)*20) + key + 1}</td>
                                                        <td data-title="Plan">{item.plan != null ? item?.plan : item?.package}</td>
                                                        <td data-title="Price">${item.order_amount}</td>
                                                        <td data-title="Method">{getPaymentMethod(item.order_paygate)}</td>
                                                        <td data-title="Status">{item.status == 2 ? (<span className='text-danger text-bold'>Cancel</span>) : (<span className='text-success text-bold'>Complete</span>)}</td>
                                                        <td data-title="Date Expired">{item.plan_id != null ? item?.date_expired : ''}</td>
                                                        <td data-title="Description">{item.description}</td>
                                                        <td data-title="Date">{item.date}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="9" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    total > 20 && !loading ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize="20" onChange={onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default OrdersList;